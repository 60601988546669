.accommodation {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-top: 3rem;
  padding-bottom: 2rem;

  @include size_S {
    padding-bottom: 0;
  }

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &_slick {
      margin-top: 4rem;
      display: flex;
      flex-direction: column-reverse;

      &_title {
        text-align: center;
        margin-top: 2rem;
        text-align: center;
        height: 2rem;

        .slick-list {
          padding: 0 40px 0 40px;
        }

        .slick-prev {
          z-index: 1;
          left: 0;
          top: 8px;

          & svg {
            width: unset !important	;
          }

          &::before {
            content: unset;
          }
        }

        .slick-next {
          z-index: 1;
          right: 20px;
          top: 8px;

          &::before {
            content: unset;
          }
        }

        .slick-slide {
          opacity: 0.25;

          @include size_XS {
            div {
              padding: 0 25px;
            }
          }
        }

        .slick-center {
          font-weight: 600;
          color: $aux_color;
          opacity: 1;
          font-size: 1.5rem;

          @include size_M {
            font-size: 1.3rem;
          }

          transition: 250ms ease-in-out;
        }
      }

      &_container {
        position: relative;
        width: 100%;
        z-index: 1;

        &_image {
          width: 100%;
          max-height: 571px;
          padding-bottom: 90px;

          @include size_XS {
            max-height: 500px;
            padding-bottom: 0px;
          }

          object-fit: cover;
        }

        &_box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 2rem;
          position: absolute;
          bottom: 0;

          @include size_XS {
            max-height: unset;
            max-width: calc(380px - 4rem);
            padding: unset;
            position: relative;
            box-shadow: unset;
          }

          left: 50%;
          transform: translateX(-50%);
          background-color: $complementary_color_light;
          max-height: 180px;
          height: 100%;
          max-width: 380px;
          width: 100%;
          border-radius: 10px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          &_title {
            font-weight: 500;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: $aux_color;

            @include size_XS {
              padding: 1rem 0;
            }
          }

          &_links {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            div,
            a {
              width: fit-content;
              position: relative;
              cursor: pointer;
              opacity: 1;

              &::after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $aux_color;
                opacity: 1;
                transition: opacity 300ms, transform 300ms;
                transform: scale(0);

                @include size_XS {
                  transform: scale(1);
                }

                transform-origin: center;
              }

              &:hover::after,
              &:focus::after {
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }

  .slick-list {
    overflow: unset;
    overflow-x: clip;
  }

  @include size_XS {
    .title_underline_complementary_small {
      &::after {
        display: none;
      }
    }
  }
}
