.wellness_section {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-top: 3rem;
  padding-bottom: 4rem;

  @include size_S {
    padding-bottom: 0;
  }

  &_container {
    display: grid;
    grid-template-columns: calc(60% - 2rem) calc(40% - 2rem);
    overflow: hidden;

    @include size_L {
      grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
    }

    @include size_M {
      grid-template-columns: 100%;
    }

    gap: 4rem;

    &_text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        margin: 0;
        margin-bottom: 2rem;
      }

      p {
        white-space: pre-line;
      }

      &_button {
        width: fit-content;
        margin: 2rem auto;
        color: $primary_color;
        justify-content: flex-end;
        font-size: 1rem;
        font-weight: 600;
    
        div {
          display: flex;
          align-items: center;
          background-color: $aux_color;
          padding: 10px 20px;
          border-radius: 5px;
        }
      }
    }

    &_img {
      display: flex;
      width: 100%;
      height: 100%;

      @include size_L {
        height: 100%;
      }

      @include size_M {
        height: 300px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: inline-block;
    }
  }
}
