.certificates {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-bottom: 2rem;

  &_container {
    &_tiles {
      padding-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      @include size_S {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }

      column-gap: 1rem;
      row-gap: 1rem;

      &_container {
        position: relative;

        &_img {
          scale: 0.75;

          img {
            display: flex;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .link {
    cursor: pointer;
    transition: $transition_slow;
    opacity: 1;

    &:hover {
      opacity: 0.3;
    }
  }
}
