.about {
  background-color: $complementary_color_dark;
  color: $secondary_color;
  padding-top: 4rem;

  .about_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 4rem;
    overflow: hidden;

    @include size_M {
      grid-template-columns: 1fr;
    }

    gap: 2rem;

    &_logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      img {
        width: 50%;
      }

      &_text{
        font-family: 'Holland';
        font-size: 4rem;
        color: $aux_color;
        margin: 0;
      }
    }

    &_img {
      display: flex;
    }

    p {
      font-weight: 300;
    }
  }
}
