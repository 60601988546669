.home {
  background-color: $complementary_color_light;

  &_container_hero {
    display: block;
    position: relative;
    height: var(--app-height);
    transition: 250ms ease-in-out;

    &_button {
      display: none;
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
      justify-content: flex-end;
      font-size: 1rem;
      font-weight: 600;

      @include size_M {
        display: flex;
      }

      div {
        display: flex;
        align-items: center;
        background-color: $aux_color;
        color: $primary_color;
        padding: 10px 20px;
        border-radius: 5px;
      }
    }
  }
}
