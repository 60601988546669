$transition: 250ms ease;
$transition_slow: 500ms ease;

$primary_color: #191919;
$secondary_color: #FFFFFF;
$aux_color: #be1e2c;
$complementary_color_dark: #191919;
$complementary_color_light: #1c1c1a;

:export {
    primaryColor: $primary_color;
    secondaryColor: $secondary_color;
    auxColor: $aux_color;
    complementaryColorDark: $complementary_color_dark;
    complementaryColorLight: $complementary_color_light;
}
