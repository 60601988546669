.b2b {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-bottom: 4rem;

  &_hero {
    position: relative;
    display: flex;
    width: 100%;

    img {
      height: calc(var(--app-height) - 8rem);
      width: 100%;
      object-fit: cover;
      object-position: top;

      @include size_S {
        height: calc(var(--app-height) - 6rem);
      }
    }

    .overlay {
      opacity: 0.5;
      background-color: #808a52;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      margin: auto;
      font-family: 'Didot';
      font-size: 4rem;

      @include size_S {
        font-size: 3rem;
      }

      @include size_XS {
        font-size: 1.5rem;
      }
    }
  }

  &_container {
    text-align: center;

    h3 {
      color: $aux_color;
      text-align: center;
      text-transform: uppercase;

      &:not(:nth-of-type(1)) {
        margin-top: 3rem;
      }
    }

    ol {
      list-style: lower-alpha;
    }

    &_box {
      display: flex;
      flex-direction: column;
      max-width: 600px;
      margin: 0 auto;
      text-align: start;

      &_grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        border-bottom: 1px dashed #ccc;

        @include size_S{
          font-size: 0.6rem;
        }

        div {
          padding: 1rem 0
        };
      }
    }
  }
}
