.story {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-bottom: 4rem;

  &_hero {
    position: relative;
    display: flex;
    width: 100%;

    img {
      height: calc(var(--app-height) - 8rem);
      width: 100%;
      object-fit: cover;
      object-position: top;

      @include size_S {
        height: calc(var(--app-height) - 6rem);
      }
    }

    .overlay {
      opacity: 0.5;
      background-color: #666959;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      margin: auto;
      font-family: 'Didot';
      font-size: 4rem;

      @include size_S {
        font-size: 3rem;
      }

      @include size_XS {
        font-size: 1.5rem;
      }
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    & > h3 {
      margin-top: 6rem;
    }

    a {
      width: fit-content;
      position: relative;
      cursor: pointer;
      opacity: 1;

      @include size_S {
        font-size: 1rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $secondary_color;
        opacity: 1;
        transition: opacity 300ms, transform 300ms;
        transform: scale(0);

        @include size_XS {
          transform: scale(1);
        }

        transform-origin: center;
      }
    }
  }

  &_grid {
    display: grid;
    grid-template-columns: calc(40% - 2rem) calc(60% - 2rem);
    overflow: hidden;
    gap: 4rem;
    padding-top: 8rem;

    @include size_L {
      grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
      padding-top: 4rem;
    }

    @include size_M {
      grid-template-columns: 100%;
    }

    &_img {
      display: flex;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &_grid_reverse {
    display: grid;
    grid-template-columns: calc(60% - 2rem) calc(40% - 2rem);
    overflow: hidden;
    gap: 4rem;
    padding-top: 8rem;

    @include size_L {
      grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
      padding-top: 4rem;
    }

    @include size_M {
      grid-template-columns: 100%;
    }

    &_text {
      display: flex;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      object-fit: cover;
    }

    & > div:nth-of-type(1) {
      order: 2;

      @include size_M {
        order: 1;
      }
    }

    & > div:nth-of-type(2) {
      order: 1;

      @include size_M {
        order: 2;
      }
    }
  }
}
