.footer {
  background: linear-gradient(
    180deg,
    $complementary_color_dark,
    $complementary_color_light 200%
  );
  color: $secondary_color;
  text-align: center;

  p {
    color: $secondary_color;
    opacity: 0.75;
    line-height: 1rem;
  }
  &_contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;

    &_logo {
      cursor: pointer;

      img {
        height: 120px;
        margin-top: 2rem;
      }
    }

    &_text {
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
      text-align: start;
      display: flex;
      justify-content: space-between;

      @include size_L {
        flex-direction: column;
        text-align: center;
        justify-content: center;
      }

      gap: 2rem;

      div {
        text-align: center;
      }
    }

    &_links {
      div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
      }
    }
  }

  &_links {
    margin-bottom: 4rem;

    div {
      font-size: 1.2rem;
      font-weight: 400;
      display: block;
      margin-bottom: 1rem;
      transition: $transition;
      cursor: pointer;
    }

    a {
      font-size: 1.2rem;
      font-weight: 400;
      display: block;
      margin-bottom: 1rem;
      transition: $transition;
      cursor: pointer;
    }
  }

  &_bottom {
    font-size: 0.8rem;

    p {
      font-size: 0.75rem;
      margin: 0;
      padding: 1rem 0;
    }
  }
}
