.blog_post {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-bottom: 4rem;

  &_hero {
    position: relative;
    display: flex;
    width: 100%;

    img {
      height: calc(var(--app-height) - 12rem);
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &_container {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    h1 {
      padding: 1rem;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 12rem;
    }

    &_back {
      color: $aux_color;
      margin-bottom: 2rem;
    }
  }

  button {
    position: relative;
    bottom: 0;
    z-index: 2;
    padding: 5px 10px;
    margin-top: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background: $secondary_color;
    color: $primary_color;
    transition: $transition;

    &.active {
      background: $aux_color;
      color: $secondary_color;
    }

    &:hover {
      opacity: 0.75;
    }
  }
}
