.reward {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-top: 3rem;
  padding-bottom: 2rem;

  @include size_S {
    padding-bottom: 0;
  }

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    a {
      width: fit-content;
      position: relative;
      cursor: pointer;
      opacity: 1;

      @include size_S {
        font-size: 1rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $secondary_color;
        opacity: 1;
        transition: opacity 300ms, transform 300ms;
        transform: scale(0);

        @include size_XS {
          transform: scale(1);
        }

        transform-origin: center;
      }
    }
  }

  &_grid {
    display: grid;
    grid-template-columns: calc(40% - 2rem) calc(60% - 2rem);
    overflow: hidden;
    gap: 4rem;

    @include size_L {
      grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
    }

    @include size_M {
      grid-template-columns: 100%;
    }

    div {
      width: 100%;
    }

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
