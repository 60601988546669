.impressum {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-bottom: 4rem;

  &_hero {
    display: flex;
    width: 100%;

    img {
      height: calc(var(--app-height) - 8rem);
      width: 100%;
      object-fit: cover;
      object-position: top;

      @include size_S {
        height: calc(var(--app-height) - 6rem);
      }
    }
  }

  &_container {
    text-align: center;

    h3 {
      color: $aux_color;
      text-align: center;
      text-transform: uppercase;

      &:not(:nth-of-type(1)) {
        margin-top: 3rem;
      }
    }
  }
}
