.mice_section {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-top: 3rem;
  padding-bottom: 4rem;

  @include size_S {
    padding-bottom: 0;
  }

  &_container {
    p {
      margin-bottom: 3rem;
    }

    &_content {
      margin-top: 4rem;
      display: grid;
      grid-template-columns: calc(40% - 2rem) calc(60% - 2rem);

      @include size_L {
        grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
      }

      @include size_M {
        grid-template-columns: 100%;
      }

      gap: 4rem;

      &_text {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        h2 {
          margin: 0;
          margin-bottom: 2rem;
        }

        p {
          white-space: pre-line;
        }

        .info_img {
          display: flex;
          justify-content: center;

          img {
            max-height: 260px;
            max-width: 100%;
          }
        }

        .stayingCoraButton {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          position: relative;
          z-index: 1;

          div {
            width: fit-content;
            position: relative;
            cursor: pointer;
            opacity: 1;

            &::after {
              content: '';
              position: absolute;
              bottom: 0px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $complementary_color_dark;
              opacity: 1;
              transition: opacity 300ms, transform 300ms;
              transform: scale(0);

              @include size_XS {
                transform: scale(1);
              }

              transform-origin: center;
            }

            &:hover::after,
            &:focus::after {
              transform: scale(1);
            }
          }
        }
      }

      &_img {
        * {
          width: 100%;
          height: 500px;

          @include size_L {
            height: 100%;
          }

          @include size_M {
            height: 300px;
          }
        }

        img {
          object-fit: cover;
        }
      }

      &_buttons {
        display: flex;
        align-items: center;

        @include size_M {
          justify-content: center;
        }

        padding-top: 2rem;
        gap: 2rem;

        div {
          display: flex;
          cursor: pointer;
          transition: $transition;
        }

        &_counter {
          font-size: 1.25rem;
          margin: 0 0 0 auto;
        }
      }
    }
  }
}
