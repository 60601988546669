.blog {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-bottom: 4rem;

  &_hero {
    position: relative;
    display: flex;
    width: 100%;

    img {
      height: calc(var(--app-height) - 8rem);
      width: 100%;
      object-fit: cover;
      object-position: top;

      @include size_S {
        height: calc(var(--app-height) - 6rem);
      }
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &_filter {
      margin-bottom: 20px;

      strong {
        display: block;
        margin-bottom: 10px;
      }

      &_tags {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }

    &_grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      @include size_M {
        grid-template-columns: 1fr;
      }

      &_card {
        a {
          display: flex;
          position: relative;
          overflow: hidden;
          border-radius: 8px;
        }

        img {
          display: flex;
          width: 100%;
          height: 400px;
          object-fit: cover;
          filter: brightness(0.7);
          transition: all 0.3s;

          &:hover {
            filter: brightness(1);
            transform: scale(1.05);
          }
        }

        &_details {
          position: absolute;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          width: 100%;
          padding: 10px;
          box-sizing: border-box;
          text-align: center;

          h2 {
            margin: 0;
            font-size: 1.2rem;
          }

          p {
            margin: 5px 0;
            font-size: 0.9rem;
          }
        }

        &_tags {
          margin: 10px 0;
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }
    }

    button {
      position: relative;
      bottom: 0;
      z-index: 2;
      padding: 5px 10px;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      background: $secondary_color;
      color: $primary_color;
      transition: $transition;

      &.active {
        background: $aux_color;
        color: $secondary_color;
      }

      &:hover {
        opacity: 0.75;
      }
    }
  }
}
