.authenticity {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-top: 3rem;
  padding-bottom: 4rem;

  @include size_S {
    padding-bottom: 0;
  }

  &_container {
    display: grid;
    grid-template-columns: calc(40% - 2rem) calc(60% - 2rem);
    overflow: hidden;
    gap: 4rem;

    @include size_L {
      grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
    }

    @include size_M {
      grid-template-columns: 100%;
    }

    &_text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        margin: 0;
        margin-bottom: 2rem;
      }

      p {
        white-space: pre-line;
      }
    }

    &_img {
      display: flex;
      width: 100%;
      height: 100%;

      @include size_L {
        height: 100%;
      }

      @include size_M {
        height: 300px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: inline-block;
    }
  }
}
