.glance {
  background-color: $primary_color;
  color: $secondary_color;
  padding-top: 3rem;
  letter-spacing: 0.1rem;
  font-weight: 200;

  &_container {
    display: grid;
    grid-template-columns: calc(40% - 2rem) calc(60% - 2rem);
    overflow: hidden;

    @include size_L {
      grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
    }

    @include size_M {
      grid-template-columns: 100%;
    }

    gap: 4rem;

    &_text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        margin: 0;
        margin-bottom: 2rem;
      }

      p {
        white-space: pre-line;
      }
    }

    &_img {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 800px;
      max-height: 500px;

      &_text {
        font-family: 'Holland';
        font-size: 4rem;
        color: $aux_color;
        margin: 0;
      }

      @include size_L {
        height: 100%;
      }

      @include size_M {
        height: 300px;
        justify-content: center;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &_button {
    & > div {
      background-color: $aux_color;
    }
  }
}
