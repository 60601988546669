#hero_slideshow {
  height: 100%;
  overflow: hidden;

  .slide {
    position: relative;
    height: 100%;
    width: 100vw;

    &_active,
    &_last {
      height: 100%;
      display: flex;

      img {
        height: 100%;
        width: 100vw;
        object-fit: cover;
      }
    }

    &_active {
      transition: transform 1s ease-in-out;
    }

    &_last {
      transition: unset;
    }

    &_overlay {
      background: linear-gradient(
        #ffffff30 0%,
        #41414150 50%,
        $primary_color 100%
      );
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      bottom: 0;
      width: 100%;
      padding: 2rem;

      &_special {
        h2,
        h3 {
          text-align: center;
          color: $secondary_color;
          text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
          font-family: 'Didot';
        }

        h2 {
          font-family: 'Holland';
          text-transform: capitalize;
          color: $aux_color;
          font-size: 7rem;
          font-weight: 400;
          text-shadow: rgb(0 0 0) 1px 1px 10px, rgb(0 0 0) 5px 5x 20px;
          margin-top: -4rem;
          margin-bottom: 8rem;

          @include size_M {
            font-size: 3rem;
            margin-top: -2rem;
            margin-bottom: 8rem;
          }

          font-weight: 400;
        }

        h3 {
          font-size: 1.5rem;
          @include size_M {
            font-size: 1rem;
          }
          margin: 0;
        }
      }

      &_normal {
        h2,
        h3 {
          text-align: center;
          color: $secondary_color;
          text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
          font-family: 'Didot';
        }

        h2 {
          font-size: 3rem;
          margin-bottom: 12rem;

          @include size_M {
            font-size: 1.75rem;
            margin-bottom: 8rem;
          }

          font-weight: 400;
        }

        h3 {
          font-size: 1.5rem;
          @include size_M {
            font-size: 1rem;
          }
          margin: 0;
        }
      }

      .nav_book {
        position: absolute;
        bottom: 4rem;
        color: $primary_color;
        justify-content: flex-end;
        font-size: 1rem;
        font-weight: 600;
        gap: 20px;

        @include size_M {
          display: none;
        }

        &_lang {
          cursor: pointer;
          transition: $transition;
        }

        div {
          display: flex;
          align-items: center;
          background-color: $aux_color;
          padding: 10px 20px;
          border-radius: 5px;
        }
      }
    }
  }
}
