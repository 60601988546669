.mice {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-bottom: 4rem;

  &_hero {
    position: relative;
    display: flex;
    width: 100%;

    img {
      height: calc(var(--app-height) - 8rem);
      width: 100%;
      object-fit: cover;
      object-position: top;

      @include size_S {
        height: calc(var(--app-height) - 6rem);
      }
    }

    .overlay {
      opacity: 0.5;
      background-color: #131d48;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50%;
      margin: auto;
      font-family: 'Didot';
      font-size: 4rem;

      @include size_S {
        font-size: 3rem;
      }

      @include size_XS {
        font-size: 1.5rem;
      }
    }
  }

  &_container {
    text-align: center;

    h2 {
      text-align: center;
      font-weight: 400;

      @include size_XS {
        font-size: 1rem;
      }
    }
  }

  &_subtext {
    h3 {
      text-align: center;
      margin: 0 auto;
    }

    h2 {
      text-align: center;
      margin: 6rem auto 1rem auto;
    }

    p {
      text-align: center;
      margin: 0 auto;
      font-size: 1.25rem;
    }

    a {
      text-align: center;
      width: fit-content;
      margin: 2rem auto;
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 600;
      background-color: $aux_color;
      color: $primary_color;
      padding: 10px 20px;
      border-radius: 5px;
    }
  }
}
