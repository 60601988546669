.offers {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-top: 3rem;
  padding-bottom: 4rem;

  &_container {
    .slick-list {
      height: 500px;

      @include size_M {
        height: 300px;
      }

      @include size_S {
        height: 200px;
      }
    }

    .slick-center {
      .offers_container_slider_container_title {
        @include size_M {
          opacity: 1;
        }
      }
    }

    .slick-dots li button:before {
      font-size: 1rem;
      top: 2rem;
      color: $secondary_color;
    }

    .slick-slide div {
      height: 500px;
      @include size_M {
        height: 300px;
      }
      @include size_S {
        height: 200px;
      }
    }

    &_slider {
      &_container {
        position: relative;
        padding: 0 1rem;

        img {
          height: 100%;
        }

        &_title {
          h2 {
            @include size_S {
              font-size: 1.25rem;
              padding: 0 0.5rem;
              margin-bottom: 1rem;
            }
          }

          a {
            width: fit-content;
            position: relative;
            cursor: pointer;
            opacity: 1;

            @include size_S {
              font-size: 1rem;
            }

            &::after {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $secondary_color;
              opacity: 1;
              transition: opacity 300ms, transform 300ms;
              transform: scale(0);

              @include size_XS {
                transform: scale(1);
              }

              transform-origin: center;
            }

            &:hover::after,
            &:focus::after {
              transform: scale(1);
            }
          }

          text-align: center;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 1rem;
          right: 1rem;
          bottom: 0;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.7);
          opacity: 0;
          transition: ease-in-out 250ms;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  @include size_S {
    padding-bottom: 0;
  }
}
