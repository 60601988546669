.miceComponents {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-top: 3rem;
  padding-bottom: 4rem;
  transition: 500ms ease-in-out;

  @include size_S {
    padding-bottom: 0;
  }

  &_container {
    p {
      margin-bottom: 4rem;
    }

    &_content {
      display: grid;
      grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);

      .info_img {
        img {
          max-width: 100%;
          margin: auto;
          padding: 1rem 0;
        }
      }

      &_list {
        margin: auto;
        margin-top: -2rem;
        padding-bottom: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include size_S{
          grid-template-columns: 1fr;
        }

        max-width: 600px;

        li::marker {
          color: $aux_color;
        }
      }

      &_reverse {
        & > div:nth-of-type(1) {
          order: 1;

          @include size_M {
            order: 2;
          }
        }

        & > div:nth-of-type(2) {
          order: 2;

          @include size_M {
            order: 1;
          }
        }
      }

      @include size_M {
        grid-template-columns: 100%;
      }

      gap: 4rem;

      &_text {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        h2 {
          color: $aux_color;
          margin: 0;
          margin-bottom: 2rem;
        }

        p {
          white-space: pre-line;
          margin-bottom: 1rem;
        }

        ul {
          li::marker {
            color: $aux_color;
          }
        }
      }

      &_buttons {
        display: flex;
        align-items: center;

        @include size_M {
          justify-content: center;
        }

        gap: 2rem;

        div {
          display: flex;
          cursor: pointer;
          transition: $transition;
        }

        &_counter {
          font-size: 1.25rem;
          margin: 0 0 0 auto;
        }
      }

      &_img {
        height: 100%;
        * {
          height: inherit;

          @include size_M {
            height: 300px;
          }
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
