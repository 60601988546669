.features {
  background-color: $complementary_color_light;
  color: $secondary_color;
  padding-top: 3rem;
  padding-bottom: 4rem;
  transition: 500ms ease-in-out;

  @include size_S {
    padding-bottom: 0;
  }

  &_container {
    p {
      margin-bottom: 1rem;
    }

    &_content {
      display: grid;
      grid-template-columns: calc(40% - 2rem) calc(60% - 2rem);

      @include size_L {
        grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
      }

      @include size_M {
        grid-template-columns: 100%;
      }

      gap: 4rem;

      &_text {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        h2 {
          color: $aux_color;
          margin: 0;
          margin-bottom: 2rem;
        }

        p {
          white-space: pre-line;
        }
      }

      &_buttons {
        display: flex;
        align-items: center;

        @include size_M {
          justify-content: center;
        }

        gap: 2rem;

        div {
          display: flex;
          cursor: pointer;
          transition: $transition;
        }

        &_counter {
          font-size: 1.25rem;
          margin: 0 0 0 auto;
        }
      }

      &_img {
        * {
          width: 100%;
          height: 400px;

          @include size_M {
            height: 300px;
          }
        }

        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .slick-active{
    z-index: 1;
  }
}
